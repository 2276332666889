import { startCase } from "lodash-es";
import qs from "qs";
import type { parseQuery } from "vue-router";
import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";

import isUnauth from "@/utils/isUnauth";
import * as redirectCookie from "@/utils/redirectUrlCookie";
import telemetry from "@/utils/telemetry";
import CertificationLandingPage from "@/views/Certifications/CertificationLandingPage.vue";
import UnauthCertLanding from "@/views/Certifications/UnauthCertificationLandingPage.vue";
import forYou from "@/views/ForYou/forYou.vue";

const CertLandingPage = isUnauth()
  ? UnauthCertLanding
  : CertificationLandingPage;

const practiceExams = "practice-exam";

const showHeader = {
  pageHeader: true,
};

const hideMarquee = {
  hideMarquee: true,
};

const scrollShowProfile = {
  scrollShowProfile: true,
};

const ContentPage = {
  ...hideMarquee,
  ...scrollShowProfile,
};

const SubPage = {
  ...showHeader,
  ...ContentPage,
};

import unauthLanding from "@/views/LandingPage/unauthLanding.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
const routes: any = [
  {
    path: "/",
    name: "home",
    component: unauthLanding,
    meta: {
      pageHeader: false,
      pageFooter: false,
      hideMarquee: true,
      unauth: true,
      hideDarkHeader: true,
    },
  },
  {
    path: "/for-you",
    name: "ForYou",
    component: forYou,
    meta: {
      ...showHeader,
      title: "For You",
    },
  },
  {
    path: "/login",
    name: "login",
    redirect: "/for-you",
  },
  {
    path: "/my-learning",
    name: "MyLearning",
    component: () => import("./views/MyLearning/IndexPage.vue"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("./views/MyLearning/myDashboard.vue"),
        meta: {
          title: "My Dashboard",
        },
      },
      {
        path: "my-list",
        name: "my-list",
        component: () => import("./views/MyLearning/myLibrary.vue"),
        meta: {
          title: "My Dashboard - My Learning",
        },
      },
      {
        path: "my-account",
        name: "my-account",
        component: () => import("./views/MyLearning/myPurchases.vue"),
        meta: {
          pageHeader: false,
          pageFooter: true,
          hideMarquee: true,
          showProfileHeader: true,
          title: "My Dashboard - My Account",
        },
      },
      {
        path: "community",
        name: "community",
        component: () => import("./views/MyLearning/community-page.vue"),
        meta: {
          title: "My Dashboard - My Community",
        },
      },
      {
        path: "discussion",
        name: "discussion",
        component: () => import("./views/MyLearning/community-page.vue"),
        meta: {
          title: "My Dashboard - My Community",
        },
      },
      {
        path: "cdl-user",
        name: "cdl-user",
        component: () => import("./views/MyLearning/cdlUserCompletes.vue"),
        meta: {
          title: "My Dashboard - My CdlUserCompletes",
        },
      },
    ],
  },
  {
    path: "/explore",
    name: "Explore",
    props: (route: any) => ({ queryParameters: route.query }),
    component: () => import("./views/Explore/IndexPage.vue"),
    redirect: { name: "all" },
    children: [
      {
        path: "",
        name: "all",
        component: () => import("./views/Search/ExploreAll.vue"),
        meta: {
          ...showHeader,
          title: "Explore - All",
          type: "all",
          unauth: true,
        },
      },
      {
        path: ":type",
        name: "explore-type",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - ",
          unauth: true,
        },
      },
      {
        path: "/explore/path",
        redirect: { name: "explore-paths" },
      },
      {
        path: "/explore/course",
        redirect: { name: "explore-courses" },
      },
      {
        path: "/explore/practice-exam",
        redirect: { name: "explore-exams" },
      },
      {
        path: "/explore/instructor-led",
        redirect: { name: "explore-instructorled" },
      },
      {
        path: "/explore/tutorials",
        redirect: { name: "explore-tutorials" },
      },
      {
        path: "/explore/video-series",
        redirect: { name: "explore-videos" },
      },
      {
        path: "/explore/podcasts",
        redirect: { name: "explore-podcasts" },
      },
      {
        path: "/explore/events-and-webinars",
        redirect: { name: "explore-events" },
      },
      {
        path: "/paths",
        name: "explore-paths",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Learning Paths ",
          type: "learning-paths",
          unauth: true,
        },
      },
      {
        path: "/courses",
        name: "explore-courses",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Learning courses ",
          type: "courses",
          unauth: true,
        },
      },
      {
        path: "/exams",
        name: "explore-exams",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Practice Exams ",
          type: "practice-exams",
          unauth: true,
        },
      },
      {
        path: "/tutorials",
        name: "explore-tutorials",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Tutorials ",
          type: "tutorials",
          unauth: true,
        },
      },
      {
        path: "/instructorled",
        name: "explore-instructorled",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Instructor Led ",
          type: "instructor-led",
          unauth: true,
        },
      },
      {
        path: "/videos",
        name: "explore-videos",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Videos",
          type: "video-series",
          unauth: true,
        },
      },
      {
        path: "/podcasts",
        name: "explore-podcasts",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Podcasts",
          type: "podcasts",
          unauth: true,
        },
      },
      {
        path: "/events",
        name: "explore-events",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Events and Webinars",
          type: "events-and-webinars",
          unauth: true,
        },
      },
      {
        path: "/challenges",
        name: "explore-challenges",
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Explore - Challenges",
          type: "challenges",
          unauth: true,
        },
      },
    ],
  },
  {
    path: "/under-construction",
    name: "under-construction",
    component: () => import("./views/FallbackPage.vue"),
    meta: {
      pageHeader: false,
      pageFooter: true,
      hideMarquee: true,
      showProfileHeader: false,
      title: "Under Construction",
      unauth: true,
    },
  },
  {
    path: "/path/:pathId",
    redirect: (to: { params: { pathId: any } }) => {
      return { name: "Path", params: { pathId: to.params.pathId } };
    },
  },
  {
    path: "/paths/:pathId",
    name: "Path",
    component: () => import("./views/LearningPathPage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Path",
      unauth: true,
    },
  },
  {
    path: "/tutorial/:tutorialId",
    redirect: (to: { params: { tutorialId: any } }) => {
      return { name: "Tutorial", params: { tutorialId: to.params.tutorialId } };
    },
  },
  {
    path: "/tutorials/:tutorialId",
    name: "Tutorial",
    component: () => import("./views/TutorialPage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Tutorial",
      unauth: true,
    },
  },
  {
    path: "/challenge/:challengeId",
    redirect: (to: { params: { challengeId: any } }) => {
      return {
        name: "Challenge",
        params: { challengeId: to.params.challengeId },
      };
    },
  },
  {
    path: "/challenges/:challengeId",
    name: "Challenge",
    component: () => import("./views/ChallengePage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Challenge",
      unauth: true,
    },
  },
  {
    path: "/instructor-led/:iltId?",
    name: "ILT",
    component: () => import("./views/ILTPage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Instructor-led Training",
      unauth: true,
    },
  },
  {
    path: "/my-score-report/:pathId?",
    name: "my-score-report",
    component: () => import("./views/MyScoreReport/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "My Score Reports",
    },
  },
  {
    path: "/my-practice-exam-score-report/:examId?",
    name: "my-practice-exam-score-report",
    component: () => import("./views/MyPracticeExamScoreReport/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "My Practice Exam Score Report",
    },
  },
  {
    path: "/practice-exam/:practiceExamId",
    redirect: (to: { params: { practiceExamId: any } }) => {
      return {
        name: practiceExams,
        params: { practiceExamId: to.params.practiceExamId },
      };
    },
  },
  {
    path: "/exams/:practiceExamId",
    name: practiceExams,
    component: () => import("./views/PracticeExamPage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Practice Exam Overview Page",
      unauth: true,
    },
  },
  {
    path: "/my-score-report/complete-certificate",
    name: "complete-certificate",
    component: () => import("./views/MyScoreReport/completeCertificate.vue"),
    meta: {
      ...ContentPage,
      title: "Completion Certificate",
    },
  },
  {
    path: "/video-series/:videoSerieId?/:videoId?",
    name: "VideoSeries",
    component: () => import("./views/VideoSeriesPage/VideoSerie.vue"),
    meta: {
      ...ContentPage,
      title: "Video Series",
    },
  },
  {
    path: "/podcast/:podcastId?",
    name: "PodcastDetails",
    component: () => import("./views/PodcastLanding/PodcastLandingPage.vue"),
    meta: {
      ...SubPage,
      title: "Podcast",
      unauth: true,
    },
  },
  {
    path: "/communities",
    name: "community-landing-page",
    component: () => import("./views/CommunityLanding/IndexPage.vue"),
    meta: {
      ...SubPage,
      title: "Communities",
      unauth: true,
    },
  },
  {
    path: "/certifications",
    name: "certifications",
    component: () => import("./views/Certifications/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Certifications",
      unauth: true,
    },
  },
  {
    path: "/certifications/:certificationId/:name?",
    name: "certification",
    component: CertLandingPage,
    meta: {
      ...SubPage,
      pageHeader: false,
      title: "Certification",
      unauth: true,
    },
  },
  {
    path: "/plans",
    name: "subscription",
    component: () => import("./views/Subscription/SubscriptionPlan.vue"),
    meta: {
      pageHeader: true,
      pageFooter: true,
      hideMarquee: true,
      hideDarkHeader: true,
      scrollShowProfile: true,
      title: "Plans",
      unauth: true,
    },
  },
  {
    path: "/purchase-paths",
    name: "purchase-paths",
    component: () => import("./views/Subscription/learningPaths.vue"),
    meta: {
      pageHeader: true,
      pageFooter: true,
      hideMarquee: true,
      hideDarkHeader: true,
      scrollShowProfile: true,
      title: "Paths",
      unauth: true,
    },
  },
  {
    path: "/purchase",
    name: "purchase",
    component: () => import("./views/Subscription/buySubscription.vue"),
    meta: {
      pageHeader: true,
      pageFooter: true,
      hideMarquee: true,
      hideDarkHeader: true,
      scrollShowProfile: true,
      title: "Purchase",
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("./views/Search/IndexPage.vue"),
    children: [
      {
        path: "",
        name: "search-all",
        props: true,
        component: () => import("./views/Search/ViewAllTypes.vue"),
        meta: {
          ...SubPage,
          title: "Search",
          unauth: true,
        },
      },
      {
        path: ":type",
        name: "search-type",
        props: true,
        component: () => import("./views/Search/ViewOneType.vue"),
        meta: {
          ...SubPage,
          title: "Search ",
          unauth: true,
        },
      },
    ],
  },
  {
    path: "/ai-window",
    name: "ai-window",
    component: () => import("./views/AiWindow/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "AiWindow",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    redirect: "/for-you",
  },
  {
    path: "/challenges/:challengeId",
    name: "Challenge",
    component: () => import("./views/ChallengePage/IndexPage.vue"),
    meta: {
      ...ContentPage,
      title: "Challenge",
      unauth: true,
    },
  },
];

let history;

if (import.meta.env.TEST) {
  history = createWebHashHistory();
}
const router = createRouter({
  history: history ?? createWebHistory(import.meta.env.BASE_URL),
  routes,
  parseQuery: qs.parse as typeof parseQuery,
  stringifyQuery(query: any): string {
    return qs.stringify(query, { arrayFormat: "comma", encode: false });
  },
  scrollBehavior(to, from, savedPosition) {
    if (to.name == from.name && to.name == "certifications") {
      const x = (document.querySelector("#result") as HTMLElement)?.offsetTop;
      return { left: 0, top: x, behavior: "smooth" };
    }
    if (to.hash == "#resubscribe") {
      const x = (document.querySelector("#resubscribe") as HTMLElement)
        ?.offsetTop;
      return { left: 0, top: x, behavior: "smooth" };
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach((to: any, _: any, next: any) => {
  if (to) {
    //Setup the page title
    let pageTitle: string = "Cisco U.";
    if (to?.meta?.title) {
      pageTitle = pageTitle + " | " + startCase(to.meta.title as string);
    }
    // alter page title
    document.title = pageTitle;

    // for search track
    telemetry.browseContent(
      pageTitle,
      to.fullPath,
      to.name,
      "Sort",
      isUnauth()
    );

    if (!import.meta.env.TEST) {
      // for any page view, track
      telemetry.view_page("view-page", to.fullPath, pageTitle, isUnauth());
    }
    if (to?.query?.returnTo) {
      const returnTo = to.query.returnTo;
      // check returnTo for query parameters
      if (returnTo.includes("?")) {
        const [path, query] = returnTo.split("?");
        next({
          path: path,
          query: qs.parse(query),
        });
      } else {
        next({
          path: to.query.returnTo,
        });
      }
    } else {
      next();
    }
  }
});

// redirect code test start
router.beforeResolve((to: any, from: any, next: any) => {
  if (!isUnauth()) {
    const redirectUrl = redirectCookie.getRedirectUrlCookie();
    if (redirectUrl) {
      redirectCookie.deleteRedirectUrlCookie();
      next(redirectUrl);
    } else {
      next();
    }
  } else {
    next();
  }
});
// redirect code test end

export default router;
